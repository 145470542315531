var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"delivery-fee","bladesData":_vm.bladesData,"bladeName":"delivery-fees","canAdd":"","canDelete":"","headers":[
        { text: 'Delivery Fee Name', value: 'lineItemName', title: 1, searchable: true },
        { text: 'Trigger Value', value: 'quantity', textFilter: 'toCurrency' },
        { text: 'Unit Price', value: 'lineValue', display: true, subtitle: 2 },
        { text: 'Status', value: 'isOn', display: true },
        { text: 'Sort Number', value: 'sortNumber' }],"navigation":"automatic-line-items","params":{ optionGroupID: 'Delivery Fee', includeDetails: false },"title":"Delivery Fees","useServerPagination":""},scopedSlots:_vm._u([{key:"lineValue",fn:function(ref){
        var item = ref.item;
return [(item.lineValueType == 'FixedValue')?_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.lineValue)))]):_c('span',[_vm._v(_vm._s(Math.pow( item.lineValue, 100 ))+"%")])]}},{key:"isOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.isOn ? 'On' : 'Off')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }