<template>
    <BT-Blade-Items
        addBladeName="delivery-fee"
        :bladesData="bladesData"
        bladeName="delivery-fees"
        canAdd
        canDelete
        :headers="[
            { text: 'Delivery Fee Name', value: 'lineItemName', title: 1, searchable: true },
            { text: 'Trigger Value', value: 'quantity', textFilter: 'toCurrency' },
            { text: 'Unit Price', value: 'lineValue', display: true, subtitle: 2 },
            { text: 'Status', value: 'isOn', display: true },
            { text: 'Sort Number', value: 'sortNumber' }]"
        navigation="automatic-line-items"
        :params="{ optionGroupID: 'Delivery Fee', includeDetails: false }"
        title="Delivery Fees"
        useServerPagination>
        <template v-slot:lineValue="{ item }">
            <span v-if="item.lineValueType == 'FixedValue'">{{ item.lineValue | toCurrency}}</span>
            <span v-else>{{ item.lineValue ** 100 }}%</span>
        </template>
        <template v-slot:isOn="{ item }">
            {{ item.isOn ? 'On' : 'Off' }}
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Delivery-Fees-Blade',
    props: {
        bladesData: null
    }
}
</script>